/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans Regular'), url('./assets/fonts/open-sans/OpenSans-Regular.woff') format('woff');
}


@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: normal;
  src: local('Open Sans Italic'), url('/assets/fonts/open-sans/OpenSans-Italic.woff') format('woff');
}


@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'), url('/assets/fonts/open-sans/OpenSans-Light.woff') format('woff');
}


@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  src: local('Open Sans Light Italic'), url('/assets/fonts/open-sans/OpenSans-LightItalic.woff') format('woff');
}


@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans SemiBold'), url('/assets/fonts/open-sans/OpenSans-SemiBold.woff') format('woff');
}


@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  src: local('Open Sans SemiBold Italic'), url('/assets/fonts/open-sans/OpenSans-SemiBoldItalic.woff') format('woff');
}


@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), url('/assets/fonts/open-sans/OpenSans-Bold.woff') format('woff');
}


@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  src: local('Open Sans Bold Italic'), url('/assets/fonts/open-sans/OpenSans-BoldItalic.woff') format('woff');
}


@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: local('Open Sans ExtraBold'), url('/assets/fonts/open-sans/OpenSans-ExtraBold.woff') format('woff');
}


@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 800;
  src: local('Open Sans ExtraBold Italic'), url('/assets/fonts/open-sans/OpenSans-ExtraBoldItalic.woff') format('woff');
}


@font-face {
  font-family: 'Open Sans Light';
  font-style: normal;
  font-weight: normal;
  src: local('Open Sans Light Regular'), url('/assets/fonts/open-sans/OpenSans-Light-webfont.woff') format('woff');
}


@font-face {
  font-family: 'Open Sans Condensed Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Open Sans Condensed Bold'), url('/assets/fonts/open-sans/OpenSans-CondBold.woff') format('woff');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: local('Poppins Regular'), url('/assets/fonts/poppins/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: local('Poppins Medium'), url('/assets/fonts/poppins/Poppins-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: local('Poppins SemiBold'), url('/assets/fonts/poppins/Poppins-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: local('Poppins Bold'), url('/assets/fonts/poppins/Poppins-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  src: local('Poppins ExtraBold'), url('/assets/fonts/poppins/Poppins-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Domine';
  src: url('/assets/fonts/domine/Domine-VariableFont_wght.ttf') format("truetype-variations");
}

@font-face {
  font-family: 'IBM Plex Mono';
  src: local('IBM Plex Mono Regular'), url('/assets/fonts/ibm-plex-mono/IBMPlexMono-Regular.ttf') format("truetype");
}

@font-face {
  font-family: 'IBM Plex Mono';
  src: local('IBM Plex Mono Medium'), url('/assets/fonts/ibm-plex-mono/IBMPlexMono-Medium.ttf') format("truetype");
}

@font-face {
  font-family: 'IBM Plex Mono';
  src: local('IBM Plex Mono SemiBold'), url('/assets/fonts/ibm-plex-mono/IBMPlexMono-SemiBold.ttf') format("truetype");
}

@font-face {
  font-family: 'IBM Plex Mono';
  src: local('IBM Plex Mono Bold'), url('/assets/fonts/ibm-plex-mono/IBMPlexMono-Bold.ttf') format("truetype");
}
