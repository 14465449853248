:root {
  // Colors
  --background: var(--basic-200);
  --comet: #545b70;
  --black: #000000;
  --dodger-blue: #2dacfc;
  --wild-sand: #f4f4f4;
  --dove-gray: #656565;
  --silver: #cccccc;
  --mine-shaft: #232323;
  --gallery: #ECECEC;
  --torch-red: #FC1E37;
  --radical-red: #ff3d71;
  --trinidad: #E44502;
  --grenadier: #ca3d02;
  --dusty-gray: #979797;
  --cloud-brust: #1E3247;
  --watusi: #fee2d6;
  --primary-600: #ec4b07;
  --primary-500: #fc4c02;
  --primary-400: #FD8540;
  --primary-300: #FEA766;
  --primary-500-bg: #ffdbcc;
  // to change
  --basic-850: #253243;
  --basic-750: #324357;

  --basic-000: #00000000;
  --basic-100: #FFFFFF;
  --basic-150: #FBFDFF;
  --basic-200: #F6FAFE;
  --basic-250: #EFF4FD;
  --basic-300: #E9EEFB;
  --basic-400: #D0DAF0;
  --basic-450: #C7D3ED;
  --basic-500: #BFCDEA;
  --basic-600: #6D82AA;
  --basic-700: #37548B;
  --basic-800: var(--basic-1000);
  --basic-900: #1A3066;
  --basic-1000: #131C32;
  --basic-1100: var(--basic-1000);

  --warning-200: #FFF1C2;
  --warning-700: #B86E00;
  --warning-900: #703C00;

  --solid-info-400: #42AAFF;
  --solid-info-600: #006FD6;
  --solid-success-600: #00B383;
  --danger-100: #FEE2D8;
  --danger-500: #F93E4F;
  --danger-600: #D62D4C;
  --ex-blue-800: #1A3066;
  --ex-blue-200: #E8F0F7;
  --transparent-danger-16: #F93E4F29;
  --transparent-primary-16: rgba(252, 76, 2, 0.16);
  --transparent-basic-16: rgba(146, 161, 175, 0.16);
  --transparent-basic-48: rgba(26, 48, 102, 0.48);

  --opaque-basic-16: rgb(238, 240, 243);
  --Peachy-keen-16: rgb(255, 227, 220);
  --login-light-gray-500: #8992a3;
  --login-light-gray-300: #8fa2b3;
  --login-light-gray-200: #f7fafc;
  --login-input-background-color: #e4ebf2;
  --login-green-validation: #00b383;
  --success-600: #00B383;
  --transparent-success-16: #00E09629;
  --info-500: #0095FF;
  --info-400: #42AAFF;
  --info-300: #94CBFF;
  --info-200: #C7E2FF;
  --hide-or-show-icon-fill-color: #8FA2B3;

  --menu-settings-color: #f9f9f9;

  --alert-tooltip-color: #101826;

  --search-topics-item-background-color: #fb4c01;
  --search-topics-hovered-topic-background-color: #ffe2d6;

  --delete-icon-background: rgba(249, 62, 79, 0.08);
  --limiters-light-grey: #95a1af;
  --validation-green: #00b383;
  --error-red: #d62d4c;
  --limiters-darkgreen: #00524c;
  --background-color-autocomplete: #f7fafc;

  --funnel-input-container-background-color: #E5EBF1;
  --funnel-input-container-border-color: #C7CEDE;
  --funnel-reception-days-background-color: #F8FAFC;

  // Alert funnel dimensions
  --alert-funnel-context-width: 220px;
  --alert-cancel-button-width: 125px;
  --alert-funnel-total-step-width: 800px;

  // Topic funnel dimensions
  --topic-cancel-button-width: 125px;

  // Dimmed colors
  --dimmed-vermilion: rgba(252, 76, 2, 0.15);

  // Fonts
  --font-family-primary: "Open Sans", sans-serif;
  --font-explain: "Comfortaa", sans-serif;
  --font-explain-module: "Montserrat", sans-serif;

  // size-headers
  --size-header-search: 102px;
  --size-header-folder: 96px;
  --size-header: 48px;
  --size-header-guest-display: 61px;
  --size-header-impacter-expanded: 232px;
  --size-header-impacter-collapsed: 124px;
  --size-header-alert-creation-funnel: 56px;
  --size-header-alert-funnel-button: 40px;
  --size-header-topic-form-button: 40px;

  // other sizes
  --size-width-menu: 48px;
  --size-image-carousel: 260px;

  // tenders
  --transparent-basic-white-16: rgba(255, 255, 255, 0.16);
  --transparent-basic-8: rgba(143, 162, 179, 0.08);
  --transparent-info-8: rgba(0, 149, 255, 0.08);
  --transparent-basic-12: rgba(26, 48, 102, 0.12);
  --transparent-info-24: rgba(0, 149, 255, 0.24);
  --transparent-info-48: rgba(0, 149, 255, 0.48);


  --danger: rgba(214, 45, 76, 1);
  --danger-transparent: rgba(249, 62, 79, 0.16);
  --no-danger-days-left: rgba(0, 149, 255, 1);
  --bg-no-danger-days-left: rgba(0, 149, 255, 0.16);
  --info-transparent: rgba(0, 149, 255, 0.16);
  --info: rgba(0, 111, 214, 1);
  --active-800: #1E1E1E;
  --active-100: rgba(233, 225, 255, 0.25);
  --box-shadow: 0px 0px 1px 0px var(--ex-transparent-basic-12, rgba(26, 48, 102, 0.12)), 0px 1px 2px 0px var(--ex-transparent-basic-12, rgba(26, 48, 102, 0.12));
  --box-shadow-active: 0px 4px 4px 0px rgba(104, 22, 204, 0.12);
  --box-shadow-hover: 0px 26px 7px 0px rgba(199, 199, 199, 0.00), 0px 16px 7px 0px rgba(199, 199, 199, 0.04), 0px 9px 6px 0px rgba(199, 199, 199, 0.13), 0px 4px 4px 0px rgba(199, 199, 199, 0.23), 0px 1px 2px 0px rgba(199, 199, 199, 0.26);
  --box-shadow-tender-btn: 0 1px 2px 0 rgba(0, 0, 0, 0.10), 0 0 1px 0 rgba(0, 0, 0, 0.15);

  // semantics
  --color-semantic-ai-bg-light: #EFE7F9;
  --color-semantic-ai-bg: #6816CC;
  --color-semantic-ai-on-bg: #EFE7F9;
  --color-semantic-ai-on-bg-light: #6816CC;

  // design system colors
  --ex-transparent-basic-white-48: rgba(255, 255, 255, 0.48);
  --ex-transparent-basic-white-80: rgba(255, 255, 255, 0.80);

  --ex-transparent-ai-8: rgba(104, 22, 204, 0.08);
  --ex-transparent-ai-16: rgba(104, 22, 204, 0.16);
  --ex-transparent-ai-24: rgba(104, 22, 204, 0.24);

  --ex-transparent-danger-8: rgba(249, 62, 79, 0.08);
  --ex-transparent-danger-16: rgba(249, 62, 79, 0.16);
  --ex-transparent-danger-24: rgba(249, 62, 79, 0.24);

  --ex-highlight-text: rgba(69, 121, 248, 0.12);

  // font sizes
  --font-size-s: 12px;
  --font-size-m: 14px;
  --font-size-l: 16px;
  --font-size-xl: 18px;

  --ex-solid-purple-800: #5412A6;
  --ex-solid-purple-700: #E9DAFB;

  // styling for the **full-text views**
  --content-width-lg: 760px;
  --content-width-md: 632px;
  --content-width-sm: 536px;
  --action-panels-width-lg: 448px;
  --action-panels-width-md: 394px;
  --action-panels-width-sm: 332px;
  --action-panels-mid-width: 220px;
  --action-panels-items-width-lg: 220px;
  --action-panels-items-width-md: 216px;

}
