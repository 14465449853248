.icon-17 {
  width: 17px !important;
  height: 17px !important;

  &.strict {
    line-height: 17px !important;
    font-size: 17px;
  }
}
.icon-18 {
  width: 18px !important;
  height: 18px !important;

  &.strict {
    line-height: 18px !important;
    font-size: 18px;
  }
}
.icon-20 {
  width: 20px !important;
  height: 20px !important;
  min-width: 20px !important;

  &.strict {
    line-height: 20px !important;
    font-size: 20px;
  }
}

.icon-40 {
  width: 40px !important;
  height: 40px !important;

  &.strict {
    line-height: 40px !important;
    font-size: 40px;
  }
}

.fill-white {
  fill: white !important;
}

.fill-basic-600 {
  fill: var(--basic-600) !important;
}

.fill-primary-500 {
  fill: var(--primary-500) !important;
}

.fill-basic-800 {
  fill: var(--basic-800) !important;
}

.icon-17 {
  min-width: 17px;
  width: 17px !important;
  height: 17px !important;
  line-height: 17px !important;
  font-size: 17px;
}

.icon-12 {
  min-width: 12px;
  width: 12px !important;
  height: 12px !important;
  line-height: 12px !important;
  font-size: 12px;
}
