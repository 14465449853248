ngx-extended-pdf-viewer {
  #toolbarContainer {
    height: 104px;
    background-color: transparent !important;
  }

  #currentOutlineItem {
    display: none !important;
  }

  .toolbar {
    border-bottom: none !important;
  }

  .highlight {
    background-color:  #fd9468 !important;

    &.selected {
      background-color: var(--primary-500) !important;
    }
  }


  .textLayer {
    opacity: 0.4 !important;
    transform-origin: 0 0;
  }

  select {
    border-radius: 8px !important;
    border: solid 1px var(--basic-600) !important;
    height: 24px !important;
    font-size: 14px !important;
    color: var(--basic-600) !important;
    text-align-last: left;
    padding-left: 4px !important;
    padding-right: 24px !important;
    background: url("/assets/images/icon-chevron-down.svg") no-repeat calc(100%);
    background-size: 24px;
    appearance: none;
    width: 128px;
  }
}
