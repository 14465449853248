.stepper-button {
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 8px 16px;
  border-radius: 10px;

  font-size: 14px;

  cursor: pointer;
}

.ex-btn {
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: none;
  background: none;

  height: 40px;
  padding: 10px 16px;
  font-size: 14px;
  font-weight: 700;

  mat-icon {
    display: flex;
    width: 20px;
    height: 20px;
  }

  &:has(mat-icon:only-child) {
    padding: 10px;
  }

  &:hover:not(:disabled) {
    filter: brightness(0.94);
  }

  &:disabled {
    opacity: 0.5;
  }

  > * + * {
    margin-left: 8px;
  }

  &.sm {
    height: 32px;
    padding: 8px 12px;
    font-size: 12px;
    font-weight: 700;

    mat-icon {
      display: flex;
      width: 16px;
      height: 16px;
    }

    &:has(mat-icon:only-child) {
      padding: 6px;
    }
  }

  &.xs {
    height: 24px;
    padding: 2px 8px;
    font-size: 12px;
    font-weight: 600;
    border-radius: 4px;

    mat-icon {
      display: flex;
      width: 12px;
      height: 12px;
    }

    &:has(mat-icon:only-child) {
      padding: 4px;
    }
  }
}

.ex-btn-basic {
  background-color: var(--basic-300);
  color: var(--basic-800);
}

.ex-btn-strong {
  background-color: var(--basic-800);
  color: var(--basic-100);
}

.ex-btn-optional {
  background-color: var(--basic-300);
  color: var(--basic-600);
}

.ex-btn-optional-transparent {
  background-color: transparent;
  color: var(--basic-600);
}

.ex-btn-danger {
  background-color: var(--transparent-danger-16);
  color: var(--danger-500);
}

.ex-btn-soft-danger {
  background-color: transparent;
  color: var(--danger-600);

  &:hover {
    background-color: var(--transparent-danger-16);
  }
}

.ex-btn-ai {
  background-color: var(--color-semantic-ai-bg-light);
  color: var(--color-semantic-ai-on-bg-light);
}

.ex-btn-success {
  background-color: var(--transparent-success-16);
  color: var(--success-600);
}
