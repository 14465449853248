.treeview-container {
  overflow-y: auto;
  padding: 1rem;

  .treeview-item {
    white-space: break-spaces;

    &:has(.no-root.root-item) {
      .treeview-item {
        margin-left: 6px;
        &::after {
          content: unset;
        }

        .treeview-path {
          display: none;
        }

        .treeview-item {
          margin-left: 24px;
          .treeview-path {
            display: block;
          }
          &::after {
            content: "";
          }
        }
      }
    }

    .treeview-item {
      margin-left: 34px;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: -1px;
        border-left: 1px solid var(--basic-400);
      }

      .treeview-item {
        margin-left: 30px;
      }
    }

    ngx-treeview-item:last-child > .treeview-item {
      &::after {
        content: unset !important;
      }

      & > .row-item::after {
        content: "";
        position: absolute;
        left: -1px;
        top: 0;
        bottom: calc(100% - 15px);
        border-left: 1px solid var(--basic-400);
      }
    }
  }
}

.dropdown-divider {
  margin: 8px -16px;
  border-color: var(--basic-300);
}

// v2
.row-item {
  padding-top: 4px;
  padding-bottom: 4px;
  position: relative;
  display: flex;
  align-items: start;

  &:hover::before {
    content: "";
    height: 100%;
    position: absolute;
    background-color: var(--basic-200);
    top: 0;
    left: -120px;
    right: -1rem;
  }

  .last-node {
    position: relative;
    z-index: 2;
  }


  img {
    height: 18px !important;
    width: 18px !important;
  }

  .treeview-path {
    height: 1px;
    width: 6px;
    background-color: var(--basic-400);
  }

  .treeview-long-path {
    height: 1px;
    width: 8px;
    background-color: var(--basic-400);
  }

  .left-group {
    display: flex;
    align-items: center;
    height: 21px;
  }

  i {
    margin-left: -10px;
    z-index: 2;
    cursor: pointer;
    display: flex;

    img {
      background-color: var(--basic-300);
      border-radius: 50%;
      padding: 2px;
    }
  }

  &.root-item {
    i {
      margin-left: 0;
    }
  }

  &.no-root.root-item {
    display: none;
  }

  .form-check {
    padding-left: 8px;
    height: unset;
    margin-bottom: 0;

    .checkbox {
      white-space: break-spaces;
      margin-bottom: 0;
      display: flex;
      align-items: center;

      .checkbox-text {
        white-space: break-spaces !important;
      }

      img {
        margin-left: 2px;
      }
    }

    &.node-root {
      padding-left: 8px;
    }
  }
}

// V2 - partie appels offre
.tenders-container {

  .treeview-container {
    overflow-x: hidden;
  }

  .treeview-item {
    position: relative;
    left: 12px;
  }

  i {
    position: relative;
    left: -18px;
    top: 1px;
  }
  .form-check-input:checked, .form-check-input:indeterminate{
    border-color: transparent;
    background-color: var(--primary-500);
  }
}
