/* Loss should be under 1, with 0 as perfect copy */
:root {
  --filter-primary-500: invert(36%) sepia(82%) saturate(3651%) hue-rotate(1deg) brightness(101%) contrast(98%); // 0.090
  --filter-basic-500: invert(92%) sepia(6%) saturate(648%) hue-rotate(173deg) brightness(90%) contrast(94%); // 0.005
  --filter-basic-600: invert(65%) sepia(31%) saturate(190%) hue-rotate(167deg) brightness(92%) contrast(94%); // 0.002
  --filter-basic-800: invert(13%) sepia(26%) saturate(1098%) hue-rotate(174deg) brightness(88%) contrast(83%); // 0.001
}

.filter-primary-500 {
  filter: var(--filter-primary-500)
}
.filter-basic-500 {
  filter: var(--filter-basic-500)
}
.filter-basic-600 {
  filter: var(--filter-basic-600)
}
.filter-basic-800 {
  filter: var(--filter-basic-800)
}
