.ng-mydp * {
  font-family: var(--font-family-primary) !important;
}

.myDpDisabled {
  background: none !important;
  color: var(--basic-600) !important;
}

.myDpDaycell:focus, .myDpMonthcell:focus, .myDpYearcell:focus {
  box-shadow: none !important;
}
.myDpSelector {
  border-radius: 16px !important;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 10%);
  border: solid 1px var(--basic-400) !important;
  padding: 0 !important;

  .myDpMonthYearSelBar {
    height: 56px;
    border-bottom: 1px solid var(--basic-400);
    border-radius: 16px 16px 0 0;
    padding-top: 10px;

    button {
      font-size: 14px;
      font-weight: bold;
      color: var(--basic-800);
    }
  }

  .myDpTableSingleYear, .myDpTableSingleMonth {
    border-radius: 8px;

    &:hover {
      background-color: var(--transparent-primary-16);
    }
  }
  .myDpTableSingleDay {
    &:hover {
      background-color: var(--transparent-primary-16);
    }

    &:not(.myDpRangeColor) ~ .myDpSelectedDay {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
  }

  .myDpNextMonth {
    color: var(--basic-600);
  }

  .myDpIconLeftArrow{
    position: absolute;
    right: 50px;
    top: 13px;
    height: 24px;
    width: 24px;
    mask-image: url(/assets/images/icon-chevron-left.svg);
    background-color: var(--basic-800);
  }

  .myDpMarkCurrDay {

    border-bottom: 0;
    &::after {
      content: "";
      width: 6px;
      height: 6px;
      background-color: var(--primary-500);
      border-radius: 6px;
      position: absolute;
      bottom: 5px;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

  .myDpMonthYearText {
    text-align: left !important;
    padding-left: 12px;
  }

  .myDpIconRightArrow{
    mask-image: url(/assets/images/icon-chevron-right.svg);
    height: 24px;
    width: 24px;
    background-color: var(--basic-800);
    margin-right: 6px;
    margin-top: 4px;
  }

  .myDpRangeColor {
    background-color: var(--primary-500) !important;
    color: var(--basic-100);

    &~ .myDpSelectedDay {
      border-bottom-right-radius: 8px;
      border-top-right-radius: 8px;
    }
  }

  .myDpMonthBtn ~ .myDpYearBtn::after {
    mask-image: url(/assets/images/icon-chevron-down.svg);
  }

  .myDpYearBtn::after {
    content: "";
    width: 24px;
    height: 24px;
    mask-image: url(/assets/images/icon-chevron-up.svg);
    background-color: var(--basic-800);
    position: absolute;
    top: 14px;
  }

  .myDpSelectedYear, .myDpSelectedMonth {
    color: var(--basic-100);
    background-color: var(--primary-500);
    border-radius: 8px;
  }

  .myDpMonthNbr {
    display: none;
  }

  table {
    height: 308px;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
    tbody {
      td {
        font-weight: 600;
        font-size: 16px;
      }

      tr:last-child {
        td:first-child {
          border-bottom-left-radius: 16px !important;
        }

        td:last-child {
          border-bottom-right-radius: 16px !important;
        }
      }
    }

    thead tr {
      height: 52px;
      border-bottom: 1px solid var(--basic-400);

      th {
        font-weight: 600;
        font-size: 16px;
        color: var(--basic-600);
      }

    }
  }
}
